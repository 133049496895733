export const isExternal = (path: string) => {
  return /^(https?:|mailto:|tel:)/.test(path);
};

export const isArray = (arg: any) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};

export const isValidURL = (url: string) => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

// 是否为数字
export const checkNumber=(val:any)=>{
  const reg:any = /^[-\\+]?[0-9]+.?[0-9]*$/;
    if (reg.test(val)) {
        return true;
    }
    return false;
}
// 百分比计算 比例计算 multiply:乘法 division：除法
export const ratio=(type:string,val:number)=>{
  const num:number=100;
  if(type==='multiply'){
      return val*num
  }else if(type==='division'){
      return val/num
  }
}
/**
 * 手机号码验证
 * @param {string} phoneNum
 * @param {string} region 手机地区 zh | hk | tw, 默认zh
 * @returns {boolean}
 */
export const isPhoneAvailable = (phoneNum: string, region: string = 'zh'): boolean => {
  let reg: RegExp;
  switch (region) {
    case 'hk':
      reg = /^([6|9])\d{7}$/;
      break;
    case 'tw':
      reg = /^09\d{8}$/;
      break;
    case 'zh':
    default:
      reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
      break;
  }
  return reg.test(phoneNum);
};

/**
 * 身份证号码验证
 * @param {string} phoneNum
 * @param {string} region 地区 zh | hk | tw, 默认zh
 * @returns {boolean}
 */
export const isIdcardAvailable = (idcardNumber: string, region: string = 'zh'): boolean => {
  let reg: RegExp;
  switch (region) {
    case 'hk':
      reg = /^[A-Z]\d{6}(\([\dA]\)|（[\dA]）|[\dA])$/;
      break;
    case 'tw':
      reg = /^[A-KM-QT-XZ]{1}[0-9]{9}$/;
      break;
    case 'zh':
    default:
      reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/;
      break;
  }
  return reg.test(idcardNumber);
};

/**
 * 邮箱验证
 * @param {string} email 需要验证的邮箱
 * @returns {boolean}
 */
export const checkEmail = (email: string): boolean => {
  // const regEmail: RegExp = /^([a-zA-Z0-9_-])+@([a-zA-Z-0-9_-])+(\.[a-zA-Z0-9_-])+/
  const regEmail: RegExp = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/;
  return regEmail.test(email)
}
/**
 * 邮箱验证
 * @param {string} email
 * @returns {boolean}
 */
// 密码要求
export const checkPassWord=(passWord:string): boolean=>{
    const regPassWord:RegExp = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W)\S{8,12}$/;
    return regPassWord.test(passWord);
}
// 检测当前模式
export const getProcess=(): string=>{
  const env:any=process.env.NODE_ENV
  return env
}

export function pausecomp(millis: number) {
  return new Promise((res)=>{
    setTimeout(()=>{
      res(true)
    },millis)
  })
}