

















































































































































import { getCountryByType, getAreaEnumApi, getInstitutionTypeApi, getStateByTypeAndCounty, getUserSchoolList } from '@/api/school_api';
import { ReqFilterTable } from '@/interface';
import { CusRoute } from '@/router';
import { isMobile } from '@/utils';
import { pausecomp } from '@/utils/validate';
import { Vue, Component, Watch } from 'vue-property-decorator';
import {getPubUrlApi} from '@/api/user_api';
interface UserSchoolList {
  institutionPicPath: string | null,
  nameChn: string,
  guid: string,
  id: string,
  name: string,
}
@Component
export default class SchoolList extends Vue {
  reqFilterTable: ReqFilterTable<{
    fkAreaCountryId: string,// 国家
    fkAreaStateId: string,// 地区
    fkInstitutionTypeId: string,// 学校类型 （大学/高中/中学等等。。。）
    businessAreaKey:string, // 地区
    fkAreaCityId:string,// 城市
    keyword: string// 关键字
  }> = {                            // 查询条件
    currentPage: 1,
    totalResult: 0,
    showCount: 12,
    totalPage: 1,
    data: {
      fkAreaCountryId: '',
      fkInstitutionTypeId: '',
      fkAreaStateId: '',
      fkAreaCityId:'',
      businessAreaKey:'',
      keyword: '',
    }
  }
  enumName:any='';
  paramsType:any='';// 判断类型当前是从哪个维度搜索：学校id或枚举（亚洲/美洲/亚太区....）
  keyword = '';
  typeList: any[] = [];      // 学校类型列表
  countryList: any[] = [];   // 国家列表
  stateList: any[] = [];     // 州省列表
  schoolList: UserSchoolList[] = []; // 学校列表
  searchList: any[] = [];    // 输入关键字的学校列表
  isLoading = false;         // 是否正在加载
  showSearchResult = false;  // 是否显示输入关键字的学校列表
  isMobile = isMobile();
  // 当前params是枚举还是id 如果是id 则显示国家 如果是枚举显示地区
  get countryName(){
    if(this.paramsType==='id'||this.paramsType===''){
      let item = this.countryList.find(item => item.id == this.reqFilterTable.data.fkAreaCountryId) ;
      return item ? item.fullName : '';
    }else{
      return this.enumName
    }
  }
  get appPaddingTop(){
    if(!this.isMobile){
      return '';
    }
    if(this.typeList.length > 2){
      return 2
    }
    return 1.2
  }
  get getPublicFileUrl(){
    return this.publicFilesUrl;
  }
  get appMinHeight(){
    if(!this.isMobile){
      return '';
    }
    return `calc(100vh - ${1.3+Number(this.appPaddingTop)}rem)`
  }
  @Watch('keyword')
  watchKeyword(str: string){
    if(str == '') this.showSearchResult = false;
  }
  async mounted() {
    // 刷新、第一次加载触发
    this.getPubUrl();
    window.addEventListener('scroll', this.onScrollPage)
  }
  public publicFilesUrl:any='';
  private prevData = 0; 
  private throttleTime = 0;
  private ticking = false;
  private scrollTop = 0 ;
  private lastScroll = 0;
  private headActive = false;       // 顶部是否隐藏，初始值是不隐藏
  private showTop = false;          // 是否显示滚动到顶部按钮，初始值是不显示
  async onScrollPage(){
    if (!this.ticking) {
      // 头部动画
      window.requestAnimationFrame(() => {
        this.scrollTop = window.scrollY;
        if (this.lastScroll == 0){
          this.headActive = true;
        } else if (this.lastScroll > this.scrollTop){
          // up
          this.headActive = true;
        } else {
          // down
          this.headActive = false;
        }
        this.lastScroll = this.scrollTop;
        this.ticking = false;
      });

      this.ticking = true;
    }
    if( !this.isMobile ) return; 
    let now = Date.now();
    const delay = 200;
    if (now - this.prevData > delay && !this.throttleTime) {
      await this.getListOnBottom();
      this.prevData = Date.now();
    } else{
      if(this.throttleTime){
        clearTimeout(this.throttleTime);
        this.throttleTime = 0;
      } else {
        this.throttleTime = setTimeout(async ()=>{
          await this.getListOnBottom()
          this.throttleTime = 0;
        }, delay)
      }
    }
  }
  public async getPubUrl(){
    const {data}:any=await getPubUrlApi();
    this.publicFilesUrl=data.data.publicFilesUrl;
    this.initData();
  }
  // 手机端滑到底部加载数据
  async getListOnBottom(){
    if (this.isLoading){
      return;
    }
    let top = window.scrollY;
    let scrollHeight = document.body.clientHeight;
    let clientHeight = window.innerHeight;
    if(top > clientHeight) {
      if(!this.showTop) this.showTop = true;
    } else{
      if (this.showTop) this.showTop = false
    }
    if (
      clientHeight+ top + 125 >= scrollHeight && 
      this.reqFilterTable.currentPage < (this.reqFilterTable.totalPage as number)
    ){
      this.reqFilterTable.currentPage ++ ;
      this.isLoading = true;
      const ret = await getUserSchoolList(this.reqFilterTable);
      if (ret.data.datas) {
        const { datas } = ret.data;
        this.schoolList.push(...datas);
      }
      await pausecomp(1000);
      this.isLoading = false;
      this.onScrollPage()
    }
  }
  beforeRouteEnter(to: CusRoute, from: CusRoute, next: Function) {
    next((vm: SchoolList) =>{
      if(to.params&&to.params.id){
        if(vm.getValueType(to.params.id)==='id'){
          vm.reqFilterTable.data.fkAreaCountryId = to.params.id;
        }else if(vm.getValueType(to.params.id)==='enum'){
          vm.reqFilterTable.data.businessAreaKey = to.params.id;
        }
      }else{
        vm.reqFilterTable.data.fkAreaCountryId='';
        vm.reqFilterTable.data.businessAreaKey='';
      }
      vm.reqFilterTable.data.fkAreaStateId = '';
      vm.reqFilterTable.data.fkAreaCityId = '';
      vm.headActive = true;
      if(from.name == 'userSchoolListById' || from.name == 'userSchoolList' || from.name == 'schooolDetail') {
        vm.initData();
      }
    });
  }
  beforeRouteUpdate(to: CusRoute, from: CusRoute, next: Function) {
    if(to.params&&to.params.id){
      if(this.getValueType(to.params.id)==='id'){
        this.reqFilterTable.data.fkAreaCountryId = to.params.id;
      }else if(this.getValueType(to.params.id)==='enum'){
        this.reqFilterTable.data.businessAreaKey = to.params.id;
      }
    }else{
      this.reqFilterTable.data.fkAreaCountryId='';
      this.reqFilterTable.data.businessAreaKey='';
    }
    this.reqFilterTable.data.fkAreaStateId = '';
    this.reqFilterTable.data.fkAreaCityId = '';
    this.initData();
    next();
  }
  beforeRouteLeave(to:CusRoute, from: CusRoute, next:Function){
    window.removeEventListener('scroll', this.onScrollPage);
    this.showSearchResult = false;
    next();
  }
  initData(){
    this.getRouteParamsType();
    this.getCountryList(this.reqFilterTable.data.fkInstitutionTypeId);
    this.getInstitutionType(Number(this.reqFilterTable.data.fkAreaCountryId));
    this.getStateList();
    this.getSchoolByFilter();
  }
  // 判断是数字还是枚举
  getValueType(value:any){
    const re =  /^[0-9]*$/g;
    let type:string='';
    if(value&&value!==''){
      if(re.test(value)){
        type='id';
      }else{
        type='enum';
      }
    }else{
      type='';
    }
    return type
  }
  // 获取当前路径参数是id还是枚举
  getRouteParamsType(){
    const paramsType:any=this.$route.params.id;
    this.paramsType=this.getValueType(paramsType)
    if(this.paramsType==='enum'){
      this.getAreaEnum();
    }
  }
  // 点击国家时
  changeCountry(id: string | number) {
    if (this.reqFilterTable.data.fkAreaCountryId == id) return;
    this.reqFilterTable.currentPage = 1;
    if(this.paramsType==='enum'){
       (this.reqFilterTable.data.fkAreaCountryId as any)=id;
        this.getInstitutionType(Number(this.reqFilterTable.data.fkAreaCountryId));
        this.getStateList();
        this.getSchoolByFilter();
    }else{
      if (id) {
        if(this.paramsType==='id'){
          this.$router.push({ name: 'userSchoolListById', params: { id: id.toString() } });
        }else{
          this.$router.push({ name: 'userSchoolListById', params: { id: id.toString() } });
        }
      } else {
        this.$router.push({ name: 'userSchoolList' });
      }
    }
  }
  // 获取枚举名字
  async getAreaEnum(){
      const {data}:any= await getAreaEnumApi(this.reqFilterTable.data.businessAreaKey);
      this.enumName=data.data;
  }
  // pc端类型改变
  changeType(id: number){
    if (this.reqFilterTable.data.fkInstitutionTypeId == id.toString()) return;
    this.reqFilterTable.data.fkInstitutionTypeId = id.toString();
    this.reqFilterTable.currentPage = 1;
    this.reqFilterTable.data.fkAreaStateId = '';
    this.getStateList();
    this.getSchoolByFilter();
  }

  changeTypeByMobile(id: number){
    if (this.reqFilterTable.data.fkInstitutionTypeId == id.toString()) return;
    this.reqFilterTable.data.fkInstitutionTypeId = id.toString();
    this.reqFilterTable.data.fkAreaStateId = '';
    this.reqFilterTable.currentPage = 1;
    this.getStateList();
    this.getSchoolByFilter();
  }
  // 改变州 省
  changeState(id: string,jap:any){
    if(jap){
      if (this.reqFilterTable.data.fkAreaCityId == id) return;
      this.reqFilterTable.data.fkAreaStateId=''
      this.reqFilterTable.data.fkAreaCityId=id
      if(id==''){
        this.reqFilterTable.data.fkAreaCityId='';
      }
    }else{
      if (this.reqFilterTable.data.fkAreaStateId == id) return;
      this.reqFilterTable.data.fkAreaStateId = id;
      this.reqFilterTable.data.fkAreaCityId='';
    }
    this.reqFilterTable.currentPage = 1;
    this.getSchoolByFilter();
  }
  search() {
    this.reqFilterTable.currentPage = 1;
    this.getSchoolByFilter();
  }
  // 学校列表
  async getSchoolByFilter() {
    const ret = await getUserSchoolList(this.reqFilterTable);
    if (ret.data.datas) {
      const { page, datas } = ret.data;
      this.schoolList = datas;
      this.reqFilterTable.showCount = page.showCount;
      this.reqFilterTable.totalResult = page.totalResult;
      this.reqFilterTable.totalPage = page.totalPage;
    }
  }
  // 跳详情
  goDetail(id: string) {
    this.$router.push({ name: 'schooolDetail', params: { id } });
  }
  // 页码改变
  currentChange(page: number) {
    this.reqFilterTable.currentPage = page;
    this.getSchoolByFilter();
  }
  // 手机版输入的时候检索关键字检索
  private keyTime = 0;
  onInputKeyword(){
    if(!this.keyTime){
      clearTimeout(this.keyTime)
      this.keyTime = 0;
    }
    this.keyTime = setTimeout(()=>{
      this.reqFilterTable.currentPage = 1;
      this.getSchoolByFilter();
    },1000);
  }
  // 获取学校类型
  async getInstitutionType(countryId: number){
    const ret = await getInstitutionTypeApi(countryId,this.reqFilterTable.data.businessAreaKey);
    if(ret.data.code == 1003 && ret.data.data){
      const list = ret.data.data;
      list.unshift({typeName: this.$t('all'), id: ''})
      this.typeList = list;
    } else {
      this.typeList = [{typeName: this.$t('all'), id: ''}]
    }
  }
  // 获取国家列表
  async getCountryList(id: string){
    const ret = await getCountryByType(id,this.reqFilterTable.data.businessAreaKey);
    this.reqFilterTable.data.fkInstitutionTypeId='';
    if (ret.data.code == 1003 && ret.data.data) {
      const list = ret.data.data;
      list.unshift({ id: '', fullName: this.$t('allCountry') })
      this.countryList = list;
    } else {
      this.countryList = [{ id: '', fullName: this.$t('allCountry') }]
    }
  }
  // 获取州省列表
  async getStateList(){
    this.reqFilterTable.data.fkAreaStateId='';
    this.reqFilterTable.data.fkAreaCityId='';
    this.stateList=[];
    if(this.reqFilterTable.data.fkAreaCountryId===''){
      return
    }
    const ret = await getStateByTypeAndCounty(
      Number(this.reqFilterTable.data.fkInstitutionTypeId), 
      this.reqFilterTable.data.fkAreaCountryId,
      this.reqFilterTable.data.businessAreaKey
    );
    if(ret.data.code == 1003 && ret.data.data){
      const list = ret.data.data;
      for(const key in list){
        if(list[key].JAP){
          list.unshift({JAP:'日本',id: '', fullName: this.$t('allState') });
          break;
        }else{
          list.unshift({id: '', fullName: this.$t('allState') });
          break;
        }
      }
      this.stateList = list;
    } else {
      this.stateList = [{ id: '', fullName: this.$t('allState') }];
    }
  }
  goTop(){
    window.scrollTo(0,0);
  }
  searchItemClick(row: any){
    this.$router.push({ name: 'schooolDetail', params: { id: row.guid } }) 
  }
}

